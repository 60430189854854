<template>
  <div class="pa-5">
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0">คิวบริการวันนี้</h4>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card
          elevation="1"
          class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
        >
        <div class="d-flex flex-row justify-space-between align-center">
          <strong>รายชื่อผู้รอรับบริการ</strong>
          <!-- <v-btn text color="primary">เพิ่มเติม >>></v-btn> -->
        </div>
        <v-data-table
          :headers="headers"
          :items="dataListCustomer"
          :search="filter_search"
          :loading="loaddata"
          loading-text="กำลังโหลด..."
        >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">
              <v-icon
                :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
              >mdi-circle
              </v-icon>
            </td>
            <td class="text-start">{{item.customer_code}}</td>
            <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
            <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
            <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
            <td class="text-end">{{item.customer_prepaid}}</td>
            <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initData"
          >
            รีเซ็ต
          </v-btn>
        </template>
        </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card
          elevation="1"
          class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
        >
        <div class="d-flex flex-row justify-space-between align-center">
          <strong>รายชื่อผู้รอปรึกษาแพทย์</strong>
          <!-- <v-btn text color="primary">เพิ่มเติม >>></v-btn> -->
        </div>
        <v-data-table
          :headers="headers"
          :items="dataListCustomer"
          :search="filter_search"
          :loading="loaddata"
          loading-text="กำลังโหลด..."
        >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">
              <v-icon
                :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
              >mdi-circle
              </v-icon>
            </td>
            <td class="text-start">{{item.customer_code}}</td>
            <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
            <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
            <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
            <td class="text-end">{{item.customer_prepaid}}</td>
            <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initData"
          >
            รีเซ็ต
          </v-btn>
        </template>
        </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card
          elevation="1"
          class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
        >
        <div class="d-flex flex-row justify-space-between align-center">
          <strong>รายชื่อผู้รอทำหัตถการ</strong>
          <!-- <v-btn text color="primary">เพิ่มเติม >>></v-btn> -->
        </div>
        <v-data-table
          :headers="headers"
          :items="dataListCustomer"
          :search="filter_search"
          :loading="loaddata"
          loading-text="กำลังโหลด..."
        >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">
              <v-icon
                :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
              >mdi-circle
              </v-icon>
            </td>
            <td class="text-start">{{item.customer_code}}</td>
            <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
            <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
            <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
            <td class="text-end">{{item.customer_prepaid}}</td>
            <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initData"
          >
            รีเซ็ต
          </v-btn>
        </template>
        </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card
          elevation="1"
          class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
        >
        <div class="d-flex flex-row justify-space-between align-center">
          <strong>รายชื่อผู้ที่ใช้บริการเรียบร้อยแล้ว</strong>
          <!-- <v-btn text color="primary">เพิ่มเติม >>></v-btn> -->
        </div>
        <v-data-table
          :headers="headers"
          :items="dataListCustomer"
          :search="filter_search"
          :loading="loaddata"
          loading-text="กำลังโหลด..."
        >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">
              <v-icon
                :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
              >mdi-circle
              </v-icon>
            </td>
            <td class="text-start">{{item.customer_code}}</td>
            <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
            <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
            <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
            <td class="text-end">{{item.customer_prepaid}}</td>
            <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initData"
          >
            รีเซ็ต
          </v-btn>
        </template>
        </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card
          elevation="1"
          class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
        >
        <div class="d-flex flex-row justify-space-between align-center">
          <strong>รายชื่อลูกค้าที่ต้องโทร</strong>
          <!-- <v-btn text color="primary">เพิ่มเติม >>></v-btn> -->
        </div>
        <v-data-table
          :headers="headers"
          :items="dataListCustomer"
          :search="filter_search"
          :loading="loaddata"
          loading-text="กำลังโหลด..."
        >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">
              <v-icon
                :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
              >mdi-circle
              </v-icon>
            </td>
            <td class="text-start">{{item.customer_code}}</td>
            <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
            <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
            <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
            <td class="text-end">{{item.customer_prepaid}}</td>
            <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initData"
          >
            รีเซ็ต
          </v-btn>
        </template>
        </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loaddata: false,
      filter_search: '',
      dataListCustomer: [{
        _id: '0000001',
        customer_code: 'WG640015',
        customer_fnameth: 'ชูใจ',
        customer_lnameth: 'นามสมมติ',
        customer_fnameen: 'Chojai',
        customer_lnameen: 'Namsommud',
        customer_nicknameth: 'กุ๊ก',
        customer_nicknameen: 'Kook',
        customer_birthdate: '1990-01-12',
        customer_activity: 'สนใจ',
        customer_activity_list: 'Botox กราม',
        customer_prepaid: 1000,
        customer_activity_list_cost: 3999
      }, {
        _id: '0000002',
        customer_code: 'WG640020',
        customer_fnameth: 'สมใจ',
        customer_lnameth: 'ใจดี',
        customer_fnameen: 'Somjai',
        customer_lnameen: 'Jaidee',
        customer_nicknameth: 'หญิง',
        customer_nicknameen: 'Ying',
        customer_birthdate: '1960-04-19',
        customer_activity: 'นัดติดตามผล',
        customer_activity_list: 'Filter ร่องแก้ม',
        customer_prepaid: 4999,
        customer_activity_list_cost: 4999
      }, {
        _id: '0000003',
        customer_code: 'WG640035',
        customer_fnameth: 'สมาน',
        customer_lnameth: 'ภักดี',
        customer_fnameen: 'Saman',
        customer_lnameen: 'Phakdee',
        customer_nicknameth: 'สมาน',
        customer_nicknameen: 'Saman',
        customer_birthdate: '1944-07-21',
        customer_activity: 'ทำ',
        customer_activity_list: 'Drip 1 ครั้งที่ 2/5',
        customer_prepaid: 10000,
        customer_activity_list_cost: 10000
      }]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'รหัส',
          align: 'center',
          value: 'customer_code',
          sortable: false
        }, {
          text: 'ชื่อ - นามสกุล',
          align: 'center',
          value: 'customer_fnameth',
          sortable: false
        }, {
          text: 'อายุ',
          align: 'center',
          value: 'customer_birthdate',
          sortable: false
        }, {
          text: 'รายการ',
          align: 'center',
          value: 'customer_activity',
          sortable: false
        }, {
          text: 'จ่ายแล้ว',
          align: 'center',
          value: 'customer_prepaid',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'customer_activity_list_cost',
          sortable: false
        }, {
          text: '',
          align: 'center',
          value: 'customer_activity',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/Dashboard.css';
</style>
